import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import "../Theme/Login.css"
const cookies = new Cookies();
export default function Login() {
    const [guildID, setGuildID] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [login, setLogin] = useState(false)
    const config = {
        method: "post",
        url: "18.130.61.187/login",
        data: {
            guildID,
            username,
            password
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        axios(config)
            .then((result) => {
                setLogin(true)
                cookies.set(process.env.secret_phrase, result.data.token, {
                    path: "/",
                });
                window.location.href = `/dashboard`
            })
            .catch((err) => { err = new Error() })
    }
    return (
        <>
            <img src={require('./logo512.png')} alt="Logo">
            </img>
            <Form>
                <Form.Group controlId="formBasicGuildID">
                    <Form.Label id="label" className='label'>Guild ID:</Form.Label>
                    <Form.Control
                        class="loginForm"
                        name="guildID"
                        value={guildID}
                        type='text'
                        onChange={(e) => setGuildID(e.target.value)}
                        placeholder="Your server's id"
                    >
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicUsername">
                    <Form.Label id="label" className='label'>Username:</Form.Label>
                    <Form.Control
                        class="loginForm"
                        name="username"
                        value={username}
                        type='text'
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder='Your assigned username'
                        required>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label id="label" className='label'>Password:</Form.Label>
                    <Form.Control
                        class="loginForm"
                        name="password"
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder='Your assigned password'
                        required={true}>
                    </Form.Control>
                </Form.Group>
                <br></br>
                <div id="login">
                    <Button variant='primary' type='submit' onClick={(e) => handleSubmit(e)}>Login</Button>

                </div>
                <br></br>
                {login ? (
                        <p id="succ"className='text-success'>You successfully logged in</p>
                    ) :
                        (
                            <p id="succ" className='text-danger'>You are not logged in</p>
                        )
                    }

            </Form>
        </>
    )
}